// import './App.css';
import * as React from 'react';
import '../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { render } from 'react-dom';
import WeddingConfirmation from './WeddingConfirmation/WeddingConfirmation';



function confirmation() {
  return (
    <WeddingConfirmation />


  )

}

export default confirmation;
