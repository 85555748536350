import React from "react";

// reactstrap components
import {
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';


// core components
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'red'

  },
  details: {
    display: 'flex',
    flexDirection: 'column'
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 151,
  },
  controls: {
    display: 'flex',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  playIcon: {
    height: 38,
    width: 38,
  },
}));



function Navbars() {

  const [days, SetDays] = React.useState(0);
  const [hours, SetHours] = React.useState(0);
  const [minutes, SetMinutes] = React.useState(0);
  const [seconds, SetSeconds] = React.useState(0);
  const [deadline, Setdeadline] = React.useState('May, 12, 2022 01:00:00 AM')
  let timer;
  const classes = useStyles();
  const theme = useTheme();

  React.useEffect(() => {
    getTimeUntil(deadline);
  }, [seconds]);


  React.useEffect(() => {
    timer = setInterval(
      () => getTimeUntil(deadline),
      1000
    );
  });


  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  }


  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      SetDays(0)
      SetHours(0)
      SetMinutes(0)
      SetSeconds(0)
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      SetDays(days)
      SetHours(hours)
      SetMinutes(minutes)
      SetSeconds(seconds)
    }
  }

  return (
    <>
      <div className="section section-navbars">

        <div id="navbar">
          <div
            className="navigation-example"
            style={{
              backgroundImage:
                "url(" + require("assets/img/bg7.jpg").default + ")",
              paddingLeft: '10%',
              paddingRight: '10%',
              paddingTop: '10%',
            }}
          >
          <div className={"CardCountDown"}>
         {leading0(seconds)==0?
         <div>
           <h2>Today, 14 Mei 2022 is our best day ever !!</h2>
           {/* <p style={{fontSize:'20px'}}>
              LIVE : Live akan diadakan menggunakan aplikasi ZOOM <br/> Pastikan anda sudah memiliki aplikasi ZOOM<br/><a style={{color:'blue', textDecoration:'underline'}} onClick={()=>{window.open('https://us02web.zoom.us/j/89838174700')}} >LINK LIVE VIA ZOOM</a>
            </p> */}
         </div>:
         <div>
          <h1 style={{marginBottom:'25px'}}>
            Counting Down </h1>
              <span className="BoxCountDown">
                {leading0(days)} D
              </span>

              <span className="BoxCountDown">
                {leading0(hours)} H
              </span>

              <span className="BoxCountDown">
                {leading0(minutes)} M
              </span>

              <span className="BoxCountDown">
                {leading0(seconds)} S
              </span></div>}
          </div>
            {/* <Card className={classes.root}>
              <div className={classes.details}>
                <CardContent className={classes.content}>
                  <Typography component="h5" variant="h5">
                    <span className="BoxCountDown">
                      {leading0(days)} Days
                    </span>

                    <span className="BoxCountDown">
                      {leading0(hours)} Hours
                    </span>

                    <span className="BoxCountDown">
                      {leading0(minutes)} Minutes
                    </span>

                    <span className="BoxCountDown">
                      {leading0(seconds)} seconds
                    </span>
                  </Typography>
                </CardContent>
              </div>
            </Card> */}

          </div>
        </div>
      </div>
    </>
  );
}

export default Navbars;
