
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages for this kit
import Index from "views/Index.js";
import Confirmation from "./confirmation/confirmation"
import Soundplay from "./components/Sound/Backsound"

ReactDOM.render(
  <BrowserRouter>
    <Soundplay />
    <Switch>
      <Switch>
        <Route path="/index/:name" render={(props) => <Index {...props} />} />
        <Route path="/index" render={(props) => <Index {...props} />} />
        {/* <Route path="/:name" render={(props) => <Index {...props} />} /> */}
        <Route
          path={"/confirmation"}
          render={(props) => <Confirmation {...props} />}
        />
        <Redirect to="/index" />
        <Redirect to="/index/:name" />
        {/* <Redirect to="/:name" /> */}
        <Redirect from="/" to="/index" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
