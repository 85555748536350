import React,{useEffect} from "react";

// reactstrap components
import {
  Badge,
  NavItem,
  NavLink,
  Nav,
  Pagination,
  PaginationItem,
  PaginationLink,
  Progress,
  Container,
  Row,
  Col,
  Alert,
} from "reactstrap";

import Button from '@mui/material/Button';
import ImgsViewer from "react-images-viewer";

import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';



import satu from '../../assets/img/prewedd/1.jpeg'
import dua from '../../assets/img/prewedd/2.jpeg'
import tiga from '../../assets/img/prewedd/3.jpeg'
import empat from '../../assets/img/prewedd/4.jpeg'
import lima from '../../assets/img/prewedd/5.jpeg'
import enam from '../../assets/img/prewedd/6.jpeg'
import tujuh from '../../assets/img/prewedd/7.jpeg'
import delapan from '../../assets/img/prewedd/8.jpeg'
import sembilan from '../../assets/img/prewedd/9.jpeg'
import sepuluh from '../../assets/img/prewedd/10.jpeg'
import sebelas from '../../assets/img/prewedd/11.jpeg'
import duabelas from '../../assets/img/prewedd/12.jpeg'
import tigabelas from '../../assets/img/prewedd/13.jpeg'
import empatbelas from '../../assets/img/prewedd/14.jpeg'
import limabelas from '../../assets/img/prewedd/15.jpeg'
import enambelas from '../../assets/img/prewedd/16.jpeg'
import tujuhbelas from '../../assets/img/prewedd/17.jpeg'
import delapanbelas from '../../assets/img/prewedd/18.jpeg'
import sembilanbelas from '../../assets/img/prewedd/19.jpeg'
import duapuluh from '../../assets/img/prewedd/20.jpeg'
import duasatu from '../../assets/img/prewedd/21.jpeg'
import duadua from '../../assets/img/prewedd/22.jpeg'
import duatiga from '../../assets/img/prewedd/23.jpeg'
import duaempat from '../../assets/img/prewedd/24.jpeg'
import dualima from '../../assets/img/prewedd/25.jpeg'
import duaenam from '../../assets/img/prewedd/26.jpeg'
import duatujuh from '../../assets/img/prewedd/27.jpeg'
import duadelapan from '../../assets/img/prewedd/28.jpeg'
import duasembilan from '../../assets/img/prewedd/29.jpeg'
import tigapuluh from '../../assets/img/prewedd/30.jpeg'
import tigasatu from '../../assets/img/prewedd/31.jpeg'
import tigadua from '../../assets/img/prewedd/32.jpeg'

// core components

const itemData = [
  {
    img:dua,
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: satu,
    title: 'Burger',
  },
  {
    img: delapan,
    title: 'Camera',
  },
  {
    img: empat,
    title: 'Coffee',
    cols: 2,
  },
  {
    img: tiga,
    title: 'Hats',
    cols: 2,
  },
  {
    img: tujuh,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: lima,
    title: 'Basketball',
  },
  {
    img: sepuluh,
    title: 'Fern',
  },
  {
    img: enam,
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  },
  {
    img: sembilan,
    title: 'Tomato basil',
  },
  {
    img:sebelas,
    title: 'Sea star',
  },
  {
    img: duabelas,
    title: 'Bike',
    cols: 2,
  },
   {
    img:duadua,
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: empatbelas,
    title: 'Burger',
  },
  {
    img: limabelas,
    title: 'Camera',
  },
  {
    img: enambelas,
    title: 'Coffee',
    cols: 2,
  },
  {
    img: duapuluh,
    title: 'Hats',
    cols: 2,
  },
  {
    img: duatiga,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img: sembilanbelas,
    title: 'Basketball',
  },
  {
    img: tujuhbelas,
    title: 'Fern',
  },
  {
    img: duasatu,
    title: 'Mushrooms',
    rows: 2,
    cols: 2,
  },
  {
    img: tigabelas,
    title: 'Tomato basil',
  },
  {
    img:delapanbelas,
    title: 'Sea star',
  },
  {
    img: duaempat,
    title: 'Bike',
    cols: 2,
  }, {
    img:dualima,
    title: 'Breakfast',
    rows: 2,
    cols: 2,
  },
  {
    img: duaenam,
    title: 'Burger',
  },
  {
    img: duatujuh,
    title: 'Camera',
  },
  {
    img: duadelapan,
    title: 'Coffee',
    cols: 2,
  },
  {
    img: duasembilan,
    title: 'Hats',
    cols: 2,
  },
  {
    img: tigapuluh,
    title: 'Honey',
    author: '@arwinneil',
    rows: 2,
    cols: 2,
  },
  {
    img:tigasatu,
    title: 'Sea star',
  },
  {
    img: tigadua,
    title: 'Bike',
  },
];

function srcset(image, size, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${size * cols}&h=${
      size * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}
function changeBackground(e) {
  e.target.style.background = 'red';
  alert(e)
}

function PaginationSection() {
  const [pills, setPills] = React.useState("2");
  const [open, setOpen] = React.useState(false);
  const [SelectedImage, setSelectedImage] = React.useState();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <div className="section section-pagination" id="gallery">
      <Container>

          <ImageList
            // sx={{ width: 500, height: 450 }}
            variant="quilted"
            cols={4}
            rowHeight={121}
          >
            {itemData.map((item) => (
              <ImageListItem 
              onClick={async () => {
                await setSelectedImage(item)
                await setOpen(true)
              }} 
              className={open?"":"img-list"} 
              key={item.img} cols={item.cols || 1} rows={item.rows || 1}>
                <img
                  {...srcset(item.img, 121, item.rows, item.cols)}
                  alt={item.title}
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>

          {SelectedImage?<Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
           <div style={{alignItems:'center', backgroundColor:'white', justifyContent:'center', borderRadius:20}}>
            
             <img
                  style={{maxWidth:'100%', maxHeight:'100%', alignSelf:'center'}}
                    src={SelectedImage.img}
                    alt={SelectedImage.title}
                    loading="lazy"
                  />
           </div>
          </Dialog>:null}

          {/* <Box sx={{ width: '100%', height: '100%', overflowY: 'scroll' }}>
            <ImageList variant="masonry" cols={3} gap={8}>
              {itemData.map((item) => (
                <ImageListItem cols={item.cols || 1} rows={item.rows || 1} key={item.img} onClick={async () => {
                  await setSelectedImage(item)
                  await setOpen(true)
                }} >
                  <img
                  style={{}}
                    src={`${item.img}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Box> */}
        </Container>
      </div>
    </>
  );
}

export default PaginationSection;
