import React from "react";
import Grid from '@mui/material/Grid';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useParams, Redirect,useLocation } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import qrcode_rek from '../../confirmation/image/qrcode_rek.jpeg';
import Button from '@mui/material/Button';

// core components

function Tabs() {
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  const { name } = useParams();
  const [OpenQrCode, setOpenQrCode] = React.useState(false)

  const CloseQRCode = () => {
    setOpenQrCode(false)
  }

  const openMapPemberkatan = () => {
    window.open("https://goo.gl/maps/7YvjnjVf2aiB1bw87")
  }
  const openMapResepsi = () => {
    window.open("https://goo.gl/maps/kwxjjCXsKDbTq5cu7")
  }

  const QRCode = () => {
    return (<Dialog
      open={OpenQrCode}
      onClose={CloseQRCode}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        className="note">
        <DialogContentText id="alert-dialog-description">
          Kamu dapat menggunakan nomor rekening mempelai dibawah ini
        </DialogContentText>
        {/* <DialogContentText id="alert-dialog-description">
          (0343249851 - Lydia Handryani Tanumiha)
        </DialogContentText> */}
        <div id="buttonCopyRek" class="row">
          <DialogContentText id="alert-dialog-description">
            Klik tombol di bawah untuk copy nomor rekening
          </DialogContentText>

          <CopyToClipboard text="0343249851">
            <buttonCopyRek style={{ width: '50%', margin: '20px' }} onClick={() => { alert('Nomor Rekening Copied ! Terima kasih') }}>0343249851</buttonCopyRek>
          </CopyToClipboard>
        </div>
        <img src={qrcode_rek} className="qrcode_rek"></img>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setOpenQrCode(false) }}>Close</Button>
      </DialogActions>
    </Dialog>)
  }

  const WindowWidth = window.innerWidth

  return (
    <>
      <div style={{
        backgroundImage: WindowWidth>500?"url(" + require("assets/img/bg11.jpg").default + ")":"url(" + require("assets/img/bgVertical.png").default + ")",
         backgroundRepeat:'no-repeat',
         backgroundSize:'100%',
         height:'100%'
      }} className="section section-tabs" id="acara">

        <Container>
          <div className="information-container">

           
            <Card className="info-lokasi">
              <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={10} sm={4} md={6}>
                  <h3 className="location-title">Pemberkatan (Family Only)</h3>
                  <p>14 Mei 2022 - 09:00 WIB</p>
                  <p>Gereja Katolik Hati Kudus - Jl. Kramat Raya No.134, RT.1/RW.9, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430 </p>
                  <p>Google Maps <a href="#" onClick={openMapPemberkatan}>klik disini...</a></p>
                </Grid>
                <Grid item xs={10} sm={4} md={6}>
                  <h3  className="location-title">Resepsi </h3>
                  <p>14 Mei 2022 - 19:00 s.d 21:00 WIB</p>
                  <p>Hotel Istana Nelayan - Jl. Gatot Subroto No. 21 Km. 5, Jatiuwung, Tangerang 15134 </p>
                  <p>Google Maps <a href="#" onClick={openMapResepsi}>klik disini...</a></p>
                </Grid>
              </Grid>
              <QRCode/>
              <center>
                <img
                  alt="..."
                  className="flower"
                  src={require("assets/img/flowerFrame.png").default}
                ></img>
              </center>
             {name? <p style={{fontSize:'13px'}}>
              Note : Dikarenakan situasi pandemi, resepsi pernikahan akan diadakan dengan tamu terbatas dengan mengikuti protokol keamanan dan kesehatan. Silahkan mengisi form konfirmasi kehadiran pada menu "CONFIRMATION" atau <a href="/confirmation">KLIK DISINI</a>
            </p>:null}

            <p style={{fontSize:'20px'}}>
              Live akan diadakan menggunakan aplikasi ZOOM, pastikan anda sudah memiliki aplikasi ZOOM<br/> Klik link untuk mengikuti live : <a style={{color:'blue', textDecoration:'underline'}} onClick={()=>{window.open('https://us02web.zoom.us/j/89838174700')}} >LINK ZOOM</a>
            </p>


              <div style={{ alignItems: 'center', textAlign: 'center', marginTop:20 }}>
                <p  style={{fontSize:'13px'}}>Kamu bisa mengirimkan online gift dengan menekan tombol di bawah ini. Kami akan dengan senang hati untuk menerimanya :)</p>
                <Button style={{ alignSelf: 'center' }} variant="outlined" onClick={() => { setOpenQrCode(true) }} >Wedding Gift</Button>
              </div>
            </Card>
          </div>

          {/* <Row>
            <Col className="ml-auto mr-auto" md="10" xl="6">
              <p className="category">Tabs with Icons on Card</p>
              <Card>
                <CardHeader>
                  <Nav className="justify-content-center" role="tablist" tabs>
                    <NavItem>
                      <NavLink
                        className={iconPills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("1");
                        }}
                      >
                        <i className="now-ui-icons objects_umbrella-13"></i>
                        Home
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("2");
                        }}
                      >
                        <i className="now-ui-icons shopping_cart-simple"></i>
                        Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("3");
                        }}
                      >
                        <i className="now-ui-icons shopping_shop"></i>
                        Messages
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={iconPills === "4" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setIconPills("4");
                        }}
                      >
                        <i className="now-ui-icons ui-2_settings-90"></i>
                        Settings
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    className="text-center"
                    activeTab={"iconPills" + iconPills}
                  >
                    <TabPane tabId="iconPills1">
                      <p>
                        I think that’s a responsibility that I have, to push
                        possibilities, to show people, this is the level that
                        things could be at. So when you get something that has
                        the name Kanye West on it, it’s supposed to be pushing
                        the furthest possibilities. I will be the leader of a
                        company that ends up being worth billions of dollars,
                        because I got the answers. I understand culture. I am
                        the nucleus.
                      </p>
                    </TabPane>
                    <TabPane tabId="iconPills2">
                      <p>
                        I will be the leader of a company that ends up being
                        worth billions of dollars, because I got the answers. I
                        understand culture. I am the nucleus. I think that’s a
                        responsibility that I have, to push possibilities, to
                        show people, this is the level that things could be at.
                        I think that’s a responsibility that I have, to push
                        possibilities, to show people, this is the level that
                        things could be at.
                      </p>
                    </TabPane>
                    <TabPane tabId="iconPills3">
                      <p>
                        I think that’s a responsibility that I have, to push
                        possibilities, to show people, this is the level that
                        things could be at. So when you get something that has
                        the name Kanye West on it, it’s supposed to be pushing
                        the furthest possibilities. I will be the leader of a
                        company that ends up being worth billions of dollars,
                        because I got the answers. I understand culture. I am
                        the nucleus.
                      </p>
                    </TabPane>
                    <TabPane tabId="iconPills4">
                      <p>
                        "I will be the leader of a company that ends up being
                        worth billions of dollars, because I got the answers. I
                        understand culture. I am the nucleus. I think that’s a
                        responsibility that I have, to push possibilities, to
                        show people, this is the level that things could be at."
                      </p>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
            <Col className="ml-auto mr-auto" md="10" xl="6">
              <p className="category">Tabs with Background on Card</p>
              <Card>
                <CardHeader>
                  <Nav
                    className="nav-tabs-neutral justify-content-center"
                    data-background-color="blue"
                    role="tablist"
                    tabs
                  >
                    <NavItem>
                      <NavLink
                        className={pills === "1" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("1");
                        }}
                      >
                        Home
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "2" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("2");
                        }}
                      >
                        Profile
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "3" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("3");
                        }}
                      >
                        Messages
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={pills === "4" ? "active" : ""}
                        href="#pablo"
                        onClick={(e) => {
                          e.preventDefault();
                          setPills("4");
                        }}
                      >
                        Settings
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardHeader>
                <CardBody>
                  <TabContent
                    className="text-center"
                    activeTab={"pills" + pills}
                  >
                    <TabPane tabId="pills1">
                      <p>
                        I think that’s a responsibility that I have, to push
                        possibilities, to show people, this is the level that
                        things could be at. So when you get something that has
                        the name Kanye West on it, it’s supposed to be pushing
                        the furthest possibilities. I will be the leader of a
                        company that ends up being worth billions of dollars,
                        because I got the answers. I understand culture. I am
                        the nucleus.
                      </p>
                    </TabPane>
                    <TabPane tabId="pills2">
                      <p>
                        I will be the leader of a company that ends up being
                        worth billions of dollars, because I got the answers. I
                        understand culture. I am the nucleus. I think that’s a
                        responsibility that I have, to push possibilities, to
                        show people, this is the level that things could be at.
                        I think that’s a responsibility that I have, to push
                        possibilities, to show people, this is the level that
                        things could be at.
                      </p>
                    </TabPane>
                    <TabPane tabId="pills3">
                      <p>
                        I think that’s a responsibility that I have, to push
                        possibilities, to show people, this is the level that
                        things could be at. So when you get something that has
                        the name Kanye West on it, it’s supposed to be pushing
                        the furthest possibilities. I will be the leader of a
                        company that ends up being worth billions of dollars,
                        because I got the answers. I understand culture. I am
                        the nucleus.
                      </p>
                    </TabPane>
                    <TabPane tabId="pills4">
                      <p>
                        "I will be the leader of a company that ends up being
                        worth billions of dollars, because I got the answers. I
                        understand culture. I am the nucleus. I think that’s a
                        responsibility that I have, to push possibilities, to
                        show people, this is the level that things could be at."
                      </p>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
}

export default Tabs;
