const API_ROOT = "https://io.dejuandroandlydia.com/"
const Confirmation_Path = "confirmation/postdatatamu"
const GetComment_Path = "confirmation/getcomment"
const PostCommnet_Path = "confirmation/postcomment"

export default {
    sendConfirmation: async (params_data, update) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "nama": params_data.Nama,
                "nohp": params_data.NoHp,
                "jumlah_orang": params_data.Jumlah_Orang,
                "kenalan": params_data.Kenalan,
                "kehadiran":params_data.Kehadiran,
                "pesan":params_data.Pesan
            })
        };
        const SendData = await fetch(`${API_ROOT}${Confirmation_Path}`, requestOptions)
        const response = await SendData.json()
        return (response)
    },
    getComment: async () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        const SendData = await fetch(`${API_ROOT}${GetComment_Path}`, requestOptions)
        const response = await SendData.json()
        return (response)
    },
    sendComment: async (params_data, update) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "nama": params_data.nama,
                "comment": params_data.comment
            })
        };
        const SendData = await fetch(`${API_ROOT}${PostCommnet_Path}`, requestOptions)
        const response = await SendData.json()
        return (response)
    },
}