/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="black">
      <Container>
        <nav>
          <ul>
          <li>
              <a
                href="https://www.linkedin.com/in/dejuandro-darmansius-111347179/"
                target="_blank"
              >
                LinkedIn
              </a>
            </li>
            <li>
              <a
                href="https://www.instagram.com/dejuandro/"
                target="_blank"
              >
                Instagram
              </a>
            </li>
          </ul>
        </nav>
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Developed by{" Dejuandro Darmansius"}
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
