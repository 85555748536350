// import './App.css';
import * as React from 'react';
import Sound from 'react-sound';
import Backsong from '../../assets/img/backsound.mp3';
import "../../assets/demo/demo.css";

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import Letter from '../../assets/img/letter2.png'



function BackSound(handleSongLoading, handleSongPlaying, handleSongFinishedPlaying) {
    const Transition = React.forwardRef(function Transition(props, ref) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const [isPlaying, setIsPlaying] = React.useState(false);
    const [open, setOpen] = React.useState(true);

    const handleClose = () => {
        setOpen(false);
        setIsPlaying(true)
    };


    const PlayStop = () => {
        setIsPlaying(!isPlaying)
    }
    const popup=()=>{
        return (
            <Dialog
                // hidden={true}
                    open={open}
                    TransitionComponent={Transition}
                    keepMounted
                    aria-describedby="alert-dialog-slide-description"
                >
                    
                    <img onClick={()=>{handleClose()}} src={Letter} className="Letter"></img>
                    {/* <DialogTitle>{"Wedding Invitation"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Special Invitation For You !
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Disagree</Button>
                    </DialogActions> */}
                </Dialog>
        )
    }
    return (
        <div>
            <div className="playsoundbutton">
                <Button  onClick={() => { PlayStop() }}>{!isPlaying ? <PlayCircleIcon sx={{fontSize:50, marginLeft:-0.5, color:'pink'}} /> : <StopCircleIcon sx={{fontSize:50, marginLeft:-0.5, color:'pink'}}/>}</Button>
                <Sound
                    url={Backsong}
                    playStatus={!isPlaying ? Sound.status.STOPPED : Sound.status.PLAYING}
                    playFromPosition={0}
                    onLoading={handleSongLoading}
                    onPlaying={handleSongPlaying}
                    onFinishedPlaying={handleSongFinishedPlaying}
                />
            </div>
                {open?popup():<div/>}
        </div>
    )

}

export default BackSound;
