/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";
// core components
import { useParams, Redirect,useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';

function IndexHeader() {
  const location = useLocation();
  let pageHeader = React.createRef();
  const { name } = useParams();
  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  const WindowWidth = window.innerWidth
  const returnToConfirmation = ()=>{
    return  <div/>
  }
  return (
    <>
      <div id="Header" className="page-header clear-filter" filter-color="blue">
        <div
          className="page-header-image"
          style={{
            backgroundImage:WindowWidth>500? "url(" + require("assets/img/header.jpg").default + ")": "url(" + require("assets/img/header2.jpg").default + ")",
          }}
          ref={pageHeader}
        ></div>
        <Container>
          <div className="content-center brand" style={{marginTop:45}}>
            {/* <img
              alt="..."
              className="n-logo"
              src={require("assets/img/now-logo.png").default}
            ></img> */}

            {/* <img src="https://www.transparentpng.com/thumb/wedding/png-icon-wedding-1.png" alt="png Icon wedding @transparentpng.com"/> */}

            <h1 className="title_invitation">Wedding Invitation</h1>
            <h1 className="h1-seo">Dejuandro Darmansius</h1>
            <h1 className="h1-seo">&</h1>
            <h1 className="h1-seo">Lydia Handryani Tanumihardjo</h1>
            {name != null ?
              <div>
                <div className="namatamu">
                  <h3 className="h4-deliver">
                    Deliver To Mr/Mrs/Ms
                  </h3>
                  <h3 className="tamu">
                    {name}
                  </h3>

                  <p className="tamu_notes">
                    we apologize if there is any misspelling name or title
                  </p>
                </div>
                <div style={{ marginTop: 20, backgroundColor: 'rgba(255,255,255, 0.3)', padding: 10, borderRadius: 10 }}>
                  <p style={{ color: 'black' }} className="konfirmasi_note">Hai, {name}. Silahkan konfirmasi kedatangan Anda
                    {/* <p href="/confirmation" style={{color:'#0f7e9b'}}>KONFIRMASI KEDATANGAN</p> */}
                  </p>
                  <Button href="/confirmation" onClick={() => { }} style={{ backgroundColor: '#0f7e9b' }} variant="contained">Konfirmasi</Button>
                </div>
                <div style={{ marginTop: 20, backgroundColor: 'rgba(255,255,255, 0.3)', padding: 10, borderRadius: 10 }}>
                  <p style={{ fontSize: '20px' }}>
                    Live akan diadakan menggunakan aplikasi ZOOM, pastikan anda sudah memiliki aplikasi ZOOM <br />klik link di bawah untuk mengikuti live<br /><a style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => { window.open('https://us02web.zoom.us/j/89838174700') }} >LINK ZOOM</a>
                  </p>
                </div>
              </div>
              : <div style={{ marginTop: 20, backgroundColor: 'rgba(255,255,255, 0.3)', padding: 10, borderRadius: 10 }}>
                <p style={{ fontSize: '20px' }}>
                  Live akan diadakan menggunakan aplikasi ZOOM, pastikan kamu sudah memiliki aplikasi ZOOM<br />Klik link di bawah untuk mengikuti live<br /><a style={{ color: 'blue', textDecoration: 'underline' }} onClick={() => { window.open('https://us02web.zoom.us/j/89838174700') }} >LINK ZOOM</a>
                </p>
              </div>}
          </div>
          {/* <h6 className="category category-absolute">
            Designed by{" "}
            <a href="http://invisionapp.com/?ref=creativetim" target="_blank">
              <img
                alt="..."
                className="invision-logo"
                src={require("assets/img/invision-white-slim.png").default}
              ></img>
            </a>
            . Coded by{" "}
            <a
              href="https://www.creative-tim.com?ref=nukr-index-header"
              target="_blank"
            >
              <img
                alt="..."
                className="creative-tim-logo"
                src={require("assets/img/creative-tim-white-slim2.png").default}
              ></img>
            </a>
            .
          </h6> */}
        </Container>
      </div>
    </>
  );
}

export default IndexHeader;
