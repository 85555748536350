import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip,
} from "reactstrap";
import MenuIcon from '@mui/icons-material/Menu';
import { useParams } from 'react-router-dom';

function IndexNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [NavBarFontColor, setNavBarFontColor] = React.useState('white')
  const { name } = useParams();
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 399 ||
        document.body.scrollTop > 399
      ) {
        setNavbarColor("");
        setNavBarFontColor("black");
      } else if (
        document.documentElement.scrollTop < 400 ||
        document.body.scrollTop < 400
      ) {
        setNavbarColor("navbar-transparent");
        setNavBarFontColor("white");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={"fixed-top indexNavbar " + navbarColor} expand="lg" style={{ backgroundColor: "#FCEDF2" }} >
        <Container>
          <div className="navbar-translate" >
            <NavbarBrand style={{ color: NavBarFontColor }}
              target="_blank"
              id="navbar-brand"
              onClick={(e) => {
                e.preventDefault();
                document
                  .getElementById("Header")
                  .scrollIntoView({ behavior: 'smooth', block: 'start' });
              }}
              href=""
            >
              Dejuandro & Lydia
            </NavbarBrand>
            <button
              className="navbar-toggler navbar-toggler"

              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button" style={{ color: NavBarFontColor }}
            >
              <MenuIcon />
              {/* <span  className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span> */}
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar>
              <NavItem>
                <NavLink
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("basic-elements")
                      .scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                >
                  <i className="now-ui-icons ui-2_favourite-28" style={{ color: NavBarFontColor }}></i>
                  <p style={{ color: NavBarFontColor }}>Our Story</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("navbar")
                      .scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                >
                  <i className="now-ui-icons ui-2_time-alarm" style={{ color: NavBarFontColor }}></i>
                  <p style={{ color: NavBarFontColor }}>Countdown</p>
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("acara")
                      .scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                >
                  <i className="now-ui-icons ui-1_calendar-60" style={{ color: NavBarFontColor }}></i>
                  <p style={{ color: NavBarFontColor }}>Event</p>
                </NavLink>
              </NavItem>


              <NavItem>
                <NavLink
                  href="/"
                  onClick={(e) => {
                    e.preventDefault();
                    document
                      .getElementById("gallery")
                      .scrollIntoView({ behavior: 'smooth', block: 'start' });
                  }}
                >
                  <i className="now-ui-icons media-1_album" style={{ color: NavBarFontColor }}></i>
                  <p style={{ color: NavBarFontColor }}>Galery</p>
                </NavLink>
              </NavItem>

              {name != null ? <NavItem>
                <NavLink
                  href="/confirmation"
                >
                  <i className="now-ui-icons location_pin" style={{ color: NavBarFontColor }}></i>
                  <p style={{ color: NavBarFontColor }}>Confirmation</p>
                </NavLink>
              </NavItem>:<div/>}



            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
