import logo from '../logo.svg';
import * as React from 'react';
import './WeddingConfirmation.css';
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import APIConfig from '../Config/ApiConfig';
import APIConfig2 from '../../confirmation/Config/ApiConfig';
import LoveGif from '../image/loveLoading.gif';
import qrcode_rek from '../image/qrcode_rek.jpeg';
import TextField from '@mui/material/TextField';
import { CopyToClipboard } from 'react-copy-to-clipboard';



function WeddingConfirmation() {

  const [Jumlah_Orang, SetJumlah_orang] = React.useState();
  const [Kenalan, SetKenalan] = React.useState('');
  const [Email, setEmail] = React.useState('');
  const [Nama, SetNama] = React.useState('');
  const [Pesan, SetPesan] = React.useState('');
  const [Kehadiran, SetKehadiran] = React.useState('bisa')
  const [NoHp, SetNoHp] = React.useState('ss');
  const [OpenMakeSure, SetOpenMakeSure] = React.useState(false);
  const [openWrongData, setOpenWrongData] = React.useState(false);
  const [Loading, SetLoading] = React.useState(false);
  const [Selesai, setSelesai] = React.useState(false);
  const [OpenQrCode, setOpenQrCode] = React.useState(false)


  const OpenPopUpWrongData = () => {
    setOpenWrongData(true);
  };

  const OpenPopUpMakeSureData = () => {
    SetOpenMakeSure(true)
  }
  const ClosePopUpMakeSureData = () => {
    SetOpenMakeSure(false)
  }
  const ClosePopUpWrongData = () => {
    setOpenWrongData(false);
  };

  const FuncJumlahOrang = (event) => {
    SetJumlah_orang(event.target.value);
  };
  const FuncKenalan = (event) => {
    SetKenalan(event.target.value);
  };
  const FuncKehadiran = (event) => {
    SetNoHp('')
    SetJumlah_orang('')
    SetKehadiran(event.target.value);
  };
  const FormButton = props => (
    <div id="button" class="row">
      <button onClick={SendHandler}>{props.title}</button>
    </div>
  );
  const SendHandler = () => {
    if (Kehadiran === "tidak" && Nama === "" || Kehadiran === "tidak" && Kenalan === "") {
      OpenPopUpWrongData()
      console.log("Failed Tidak")
    } else {
      if (Kehadiran === "bisa" && Nama === "" || Kehadiran === "bisa" && Kenalan === "" || Kehadiran === "bisa" && NoHp === "" || Kehadiran === "bisa" && Jumlah_Orang === "") {
        OpenPopUpWrongData()
      } else {
        if(Kehadiran == "tidak"){
          SetJumlah_orang(0)
        }
        OpenPopUpMakeSureData()
        console.log("Okay")
      }
    }
  }

  const openMapPemberkatan = () => {
    window.open("https://goo.gl/maps/7YvjnjVf2aiB1bw87")
  }
  const openMapResepsi = () => {
    window.open("https://goo.gl/maps/kwxjjCXsKDbTq5cu7")
  }



  const PopUpWrongData = () => {
    return (<Dialog
      open={openWrongData}
      onClose={ClosePopUpWrongData}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Wahh... Ada data yang salah nih, atau belum lengkap. Cek dulu yah... :)"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Salam dari Dodo & Lydia !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ClosePopUpWrongData}>Lengkapi</Button>
      </DialogActions>
    </Dialog>)
  }

  const PopUpLoading = () => {
    return (<Dialog
      open={Loading}
      // onClose={CloseLoading}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Mengirim Data...."}
      </DialogTitle>
      <DialogContent>

        <img src={LoveGif} className="LoveLoading"></img>
        <DialogContentText id="alert-dialog-description">
          Salam dari Dodo & Lydia !
        </DialogContentText>
      </DialogContent>
      <DialogActions>
      </DialogActions>
    </Dialog>)
  }

  const PopUpMakeSure = () => {
    return (<Dialog
      open={OpenMakeSure}
      onClose={ClosePopUpMakeSureData}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {Kehadiran === "bisa" ? <DialogTitle id="alert-dialog-title">
        {"Pastikan data kamu bener ya !"}
      </DialogTitle> : <DialogTitle id="alert-dialog-title">
        {"Pastikan data kamu bener ya !:)"}
      </DialogTitle>}
      <DialogContent>
        {Kehadiran === "bisa" ?
          <DialogContentText id="alert-dialog-description">
            KEHADIRAN : HADIR
          </DialogContentText> : <DialogContentText id="alert-dialog-description">
            KEHADIRAN : TIDAK BISA HADIR
          </DialogContentText>}
        <DialogContentText id="alert-dialog-description">
          Nama : {Nama}
        </DialogContentText>
        {Kehadiran === "bisa" ?
          <div>
            <DialogContentText id="alert-dialog-description">
              No Hp : {NoHp}
            </DialogContentText>
            <DialogContentText id="alert-dialog-description">
              Jumlah Orang : {Jumlah_Orang}
            </DialogContentText>
          </div>
          : <div />}
        <DialogContentText id="alert-dialog-description">
          Teman/kenalan dari : {Kenalan}
        </DialogContentText>
        <DialogContentText id="alert-dialog-description">
          Pesan : {Pesan}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ClosePopUpMakeSureData}>Ubah</Button>
        <Button onClick={kirim}>Kirim !</Button>
      </DialogActions>
    </Dialog>)
  }

  const CloseQRCode = () => {
    setOpenQrCode(false)
  }

  const CopyNorek = () => {
    var copyText = document.getElementById("myInput");

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);
  }
  
  const QRCode = () => {
    return (<Dialog
      open={OpenQrCode}
      onClose={CloseQRCode}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent
        className="note">
        <DialogContentText id="alert-dialog-description">
          Kamu dapat menggunakan nomor rekening mempelai dibawah ini
        </DialogContentText>
        {/* <DialogContentText id="alert-dialog-description">
          (0343249851 - Lydia Handryani Tanumiha)
        </DialogContentText> */}
        <div id="buttonCopyRek" class="row">
          <DialogContentText id="alert-dialog-description">
            Klik tombol di bawah untuk copy nomor rekening
          </DialogContentText>

          <CopyToClipboard text="0343249851">
            <buttonCopyRek style={{ width: '50%', margin: '20px' }} onClick={() => { alert('Nomor Rekening Copied ! Terima kasih') }}>0343249851</buttonCopyRek>
          </CopyToClipboard>
        </div>
        <img src={qrcode_rek} className="qrcode_rek"></img>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { setOpenQrCode(false) }}>Close</Button>
      </DialogActions>
    </Dialog>)
  }

  const FormSelesai = () => {
    return (<div className="noteSelesai">
      <DialogTitle id="alert-dialog-title">Terima kasih {Nama} telah mengisi confirmation form pernikahan kami.</DialogTitle>
      {Kehadiran === "bisa" ?
        <DialogContentText>Data kamu telah diterima, jika ada informasi perubahan rencana atau yang lainnya akan kami hubungi via whatsapp ke {NoHp}. Terima kasih :)</DialogContentText> :
        <DialogContentText>Data kamu telah diterima. Terima kasih :)</DialogContentText>}
    </div>)
  }

  const kirim = async () => {
    SetLoading(true) 
    
    const DataTamu = { "Nama": Nama, "NoHp": NoHp, "Kenalan": Kenalan, "Jumlah_Orang": Jumlah_Orang, "Kehadiran": Kehadiran, "Pesan": Pesan }
    console.log(DataTamu)
   
    const Response = await APIConfig.sendConfirmation(DataTamu)

    const DataComment = { "nama": Nama, "comment": Pesan }
    const ResponseComment = await APIConfig.sendComment(DataComment)
    setTimeout(() => {
      if (Response.status === '200') {
        SetLoading(false)
        setSelesai(true)
      } else {
        alert('Ouch.. Telah terjadi kesalahan, mohon refresh browser kamu, dan isi ulang form nya. Jika terjadi lagi mohon hubungi 0811668389 :) Terima kasih')
      }
    }, 1000)
  }
  const WindowHeight = window.innerHeight

  return (
    <div className="ConfirmationBackground" style={{height:Selesai?WindowHeight:null}}>
      <h3 className="titleDejuandro">Dejuandro Darmansius </h3>
      <h3 className="title">& </h3>
      <h3 className="title">Lydia Handryani Tanumihardjo</h3>
      <div id="loginform" style={{height:Selesai?null:'100%'}}>
        <h2 id="headerTitle">Wedding Confirmation</h2>
        {Selesai ?
          <FormSelesai /> :

          <div>
            <div className="note">
              <p>Form konfirmasi kehadiran di pernikahan kami :)</p>
            </div>
            {/* <div class="row">
            <label>Email</label>
            <input placeholder="Masukan email Anda" type="emmail" value={Email} onChange={(e) => { setEmail(e.target.value) }} />
          </div> */}
            <div className="select">
              <FormControl component="fieldset">
                <FormLabel component="legend">Apakah Kamu bisa hadir di pernikahan kami ? </FormLabel>
                <RadioGroup
                  row
                  aria-label="kehadiran"
                  name="row-radio-buttons-group"
                  value={Kehadiran}
                  onChange={FuncKehadiran}>
                  <FormControlLabel value="bisa" control={<Radio />} label="Bisa Hadir" />
                  <FormControlLabel value="tidak" control={<Radio />} label="Tidak Bisa Hadir" />

                </RadioGroup>
              </FormControl>
            </div>
            <div class="row">
              <label>Nama</label>
              <input placeholder="Masukin nama kamu" type="text" value={Nama} onChange={(e) => { SetNama(e.target.value) }} />
            </div>
            {Kehadiran === "bisa" ? <div>
              <div class="row">
                <label>Nomor handphone - Format kode negara : (Ex: +62)</label>
                <input placeholder="Masukin No HP Aktif WhatsApp" type="number" value={NoHp} onChange={(e) => { SetNoHp(e.target.value) }} />
              </div>
              <div className="select">
                <FormControl sx={{ minWidth: '100%' }}>
                  <InputLabel id="demo-simple-select-autowidth-label">Pilih Jumlah Orang</InputLabel>
                  <Select
                    labelId="demo-simple-select-autowidth-label"
                    id="demo-simple-select-autowidth"
                    value={Jumlah_Orang}
                    onChange={FuncJumlahOrang}
                    autoWidth
                    label="Jumlah_Orang"
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>1 Orang (Termasuk Kamu)</MenuItem>
                    <MenuItem value={2}>Max 2 Orang (Termasuk Kamu )</MenuItem>
                  </Select>
                </FormControl>
              </div>
            </div>

              : <div />}
            <div className="select">
              <FormControl component="fieldset">
                <FormLabel component="legend">Teman / kenalan dari : </FormLabel>
                <RadioGroup row
                  aria-label="gender"
                  name="row-radio-buttons-group"
                  value={Kenalan}
                  onChange={FuncKenalan}>
                  <FormControlLabel value="dodo" control={<Radio />} label="Dejuandro Darmansius / Dodo" />
                  <FormControlLabel value="lydia" control={<Radio />} label="Lydia Handryani Tanumihardjo" />

                </RadioGroup>
              </FormControl>
            </div>
            <div class="row">
              <label>Tuliskan pesan untuk kami </label>
              <TextField
                id="outlined-multiline-static"
                placeholder="Tuliskan Pesan"
                multiline
                rows={3}
                style={{ width: '80%' }}
                defaultValue="Default Value"
                value={Pesan}
                onChange={(e) => { SetPesan(e.target.value) }}
              />
              {/* <TextareaAutosize aria-label="minimum height"
                minRows={3}
                style={{ width: '50%' }} placeholder="Masukin No HP Aktif WhatsApp" type="text" value={NoHp} onChange={(e) => { SetNoHp(e.target.value) }} /> */}
            </div>
            <div className="flower-container">
              <img
                alt="..."
                className="flower"
                src={require("../flower.png").default}
              ></img>
            </div>
            <div className="information-container">
              <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                <Grid item xs={10} sm={4} md={6}>
                  <h3>Pemberkatan (Family Only)</h3>
                  <p>14 Mei 2022 - 09:00 WIB</p>
                  <p>Gereja Katolik Hati Kudus - Jl. Kramat Raya No.134, RT.1/RW.9, Kenari, Kec. Senen, Kota Jakarta Pusat, Daerah Khusus Ibukota Jakarta 10430 </p>
                  <p>Google Maps <a href="#" onClick={openMapPemberkatan}>klik disini...</a></p>
                </Grid>
                <Grid item xs={10} sm={4} md={6}>
                  <h3>Resepsi </h3>
                  <p>14 Mei 2022 - 19:00 s.d 21:00 WIB</p>
                  <p>Hotel Istana Nelayan - Jl. Gatot Subroto No. 21 Km. 5, Jatiuwung, Tangerang 15134 </p>
                  <p>Google Maps <a href="#" onClick={openMapResepsi}>klik disini...</a></p>
                </Grid>
              </Grid>
            </div>
            <div className="note">

            <div style={{ alignItems: 'center', textAlign: 'center' }}>
                <p>Kamu bisa mengirimkan online gift dengan menekan tombol di bawah ini. Kami akan dengan senang hati untuk menerimanya :)</p>
                <Button style={{ alignSelf: 'center' }} variant="outlined" onClick={() => { setOpenQrCode(true) }} >Wedding Gift</Button>
              </div>
            </div>

            <FormButton title="Kirim" />
            <PopUpWrongData />
            <PopUpMakeSure />
            <PopUpLoading />
            <QRCode />
          </div>}
      </div>
    </div>
  );
}

export default WeddingConfirmation;
