import React from "react";
// react plugin used to create switch buttons
import Switch from "react-bootstrap-switch";
// plugin that creates slider
import Slider from "nouislider";

// reactstrap components
import {
  Button,
  Label,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import "./style.css";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
// core components

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

function BasicElements() {
  const [leftFocus, setLeftFocus] = React.useState(false);
  const [rightFocus, setRightFocus] = React.useState(false);
  const classes = useStyles();
  // React.useEffect(() => {
  //   if (
  //     !document
  //       .getElementById("sliderRegular")
  //       .classList.contains("noUi-target")
  //   ) {
  //     Slider.create(document.getElementById("sliderRegular"), {
  //       start: [50],
  //       connect: [true, false],
  //       step: 0.5,
  //       range: { min: 0, max: 100 },
  //     });
  //   }
  //   if (
  //     !document.getElementById("sliderDouble").classList.contains("noUi-target")
  //   ) {
  //     Slider.create(document.getElementById("sliderDouble"), {
  //       start: [20, 80],
  //       connect: [false, true, false],
  //       step: 1,
  //       range: { min: 0, max: 100 },
  //     });
  //   }
  // });
  return (
    <>

      <div className="section section-basic" id="basic-elements">
        <div className="storyContainer">
          <img
            alt="..."
            className="birdStory"
            src={require("assets/img/love-bird.png").default}
          ></img>

          <Grid container
            direction="row"
            justify="center"
            alignItems="center"
            spacing={0} >
            <Grid item xs={12} sm={3} md={3}   >
              <div style={{ padding: '10px' }}>
                <img
                  alt="..."
                  className="photo"
                  src={require("assets/img/dodo.png").default}
                ></img>
                
                <h3 className="Nama_mempelai">Dejuandro Darmansius</h3>
                  <p className="keterangan_mempelai">Putra kedua, Anak keempat dari </p>
                  <p className="keterangan_mempelai">Bpk. Yosef Handi Darmansius</p>
                  <p className="keterangan_mempelai">&</p>
                  <p className="keterangan_mempelai">Ibu Bestariyanti Purnama</p>
              </div>
            </Grid>

            <Grid item xs={12} sm={3} md={3} >
              <div style={{ padding: '10px' }}>
                <img
                  alt="..."
                  className="photo"
                  src={require("assets/img/lydia.png").default}
                ></img>
               
               <h3 className="Nama_mempelai">Lydia Handryani Tanumihardjo</h3>
                  <p className="keterangan_mempelai">Putri tunggal, Anak pertama dari </p>
                  <p className="keterangan_mempelai">Bpk. Ferdy Tanumihardjo</p>
                  <p className="keterangan_mempelai">&</p>
                  <p className="keterangan_mempelai">Ibu Jonita</p>
              </div>
            </Grid>
          </Grid>


          <img
            alt="..."
            className="flower"
            src={require("assets/img/flowerFrame.png").default}
          ></img>
          <h2 >-- Our Story --</h2>
        </div>

        <Grid container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0} 
          style={{marginBottom:20}} >
          <Grid item xs={12} sm={3} md={3}   >
          <div style={{padding:'10px'}}>
          <img
              alt="..."
              className="photo"
              src={require("assets/img/dodo_lydia01.jpg").default}
            ></img>
          </div>
          </Grid>

          <Grid item xs={12} sm={3} md={3} >
            {/* <div className="story" style={{height:350}}>
              <h2>First Met</h2>
              <h5>At the end of the week, on June 15 2019, is the day we first met. After chatting for long time about work, current life and childhood stories, our friendship become stronger into a relationship. We care about each other and look out for each other.</h5>
            </div> */}
            <div>
            <img
              alt="..."
              className="photo"
              src={require("assets/img/StoryFirstMet.png").default}
              style={{maxWidth:'80%'}}
            ></img>
            </div>
          </Grid>
        </Grid>

        <Grid container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={0} >
          <Grid item xs={12} sm={3} md={3} >
            {/* <div className="story" style={{ height: 350 }}>
              <h2>Our Love</h2>
              <h5>With different characters, different mind, and a lot of emotional moments that we passed, Thanks God we both got through it well. We learn to trust each other, think maturely, learn to love sincerely, understand each other, learn to admit mistakes and apologize.
                A new chapter of our adventure begins. We loved with a love that was more than love.
              </h5>
            </div> */}
            <div>
            <img
              alt="..."
              className="photo"
              src={require("assets/img/OurLove.png").default}
              style={{maxWidth:'80%'}}
            ></img>
            </div>
          </Grid>
          <Grid item xs={12} sm={3} md={3}   >
            <img
              alt="..."
              className="photo"
              style={{marginTop:20}}
              src={require("assets/img/dodo_lydia02.jpg").default}
            ></img>
          </Grid>
        </Grid>
        <Container>
          {/* <Row className="justify-content-center" >
            <img
              alt="..."
              className="birdStory"
              src={require("assets/img/love-bird.png").default}
            ></img>
          </Row>
          <Row className="justify-content-center" >

            <Col className="ml-auto mr-auto" md="10" xl="5" xs="5">  <img
              alt="..."
              className="photo"
              src={require("assets/img/dodo_lydia01.jpg").default}
            ></img>
            </Col>
            <Col className="ml-auto mr-auto" md="10" xl="5" xs="auto">
              <h2>TEsting</h2>
              <p>
                asdasd
              </p>
            </Col>


          </Row> */}
          {/* <h3 className="title">Basic Elements</h3>
          <h4>Buttons</h4>
          <p className="category">Pick your style</p>
          <Row  className="justify-content-center" >
             
        <img
              alt="..."
              className="birdStory"
              src={require("assets/img/love-bird.png").default}
            ></img>
            <Col md="10">
              <Button color="info" type="button">
                Default
              </Button>
              <Button className="btn-round" color="info" type="button">
                Round
              </Button>
              <Button className="btn-round" color="info" type="button">
                <i className="now-ui-icons ui-2_favourite-28"></i>
                With Icon
              </Button>
              <Button className="btn-icon btn-round" color="info" type="button">
                <i className="now-ui-icons ui-2_favourite-28"></i>
              </Button>
              <Button className="btn-round" color="info" outline type="button">
                Outline
              </Button>
            </Col>
          </Row>
          <p className="category">Pick your size</p>
          <Row>
            <Col md="10">
              <Button color="info" size="sm">
                Small
              </Button>
              <Button color="info">Regular</Button>
              <Button color="info" size="lg">
                Large
              </Button>
            </Col>
          </Row>
          <p className="category">Pick your color</p>
          <Row>
            <Col md="10">
              <Button color="default">Default</Button>
              <Button color="primary">Primary</Button>
              <Button color="info">Info</Button>
              <Button color="success">Success</Button>
              <Button color="warning">Warning</Button>
              <Button color="danger">Danger</Button>
              <Button className="btn-neutral" color="default">
                Neutral
              </Button>
            </Col>
          </Row>
          <h4>Links</h4>
          <Row>
            <Col md="8">
              <Button color="link">Default</Button>
              <Button className="btn-link" color="primary">
                Primary
              </Button>
              <Button className="btn-link" color="info">
                Info
              </Button>
              <Button className="btn-link" color="success">
                Success
              </Button>
              <Button className="btn-link" color="warning">
                Warning
              </Button>
              <Button className="btn-link" color="danger">
                Danger
              </Button>
            </Col>
          </Row>
          <div className="space-70"></div>
          <div id="inputs">
            <h4>Inputs</h4>
            <p className="category">Form Controls</p>
            <Row>
              <Col lg="3" sm="6">
                <FormGroup>
                  <Input
                    defaultValue=""
                    placeholder="Regular"
                    type="text"
                  ></Input>
                </FormGroup>
              </Col>
              <Col lg="3" sm="6">
                <FormGroup className="has-success">
                  <Input
                    className="form-control-success"
                    defaultValue="Success"
                    type="text"
                  ></Input>
                </FormGroup>
              </Col>
              <Col lg="3" sm="6">
                <FormGroup className="has-danger">
                  <Input
                    className="form-control-danger"
                    defaultValue="Error Input"
                    type="email"
                  ></Input>
                </FormGroup>
              </Col>
              <Col lg="3" sm="6">
                <InputGroup className={leftFocus ? "input-group-focus" : ""}>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fa fa-user-circle"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Left Font Awesome Icon"
                    type="text"
                    onFocus={() => setLeftFocus(true)}
                    onBlur={() => setLeftFocus(false)}
                  ></Input>
                </InputGroup>
              </Col>
              <Col lg="3" sm="6">
                <InputGroup className={rightFocus ? "input-group-focus" : ""}>
                  <Input
                    placeholder="Right Nucleo Icon"
                    type="text"
                    onFocus={() => setRightFocus(true)}
                    onBlur={() => setRightFocus(false)}
                  ></Input>
                  <InputGroupAddon addonType="append">
                    <InputGroupText>
                      <i className="now-ui-icons users_single-02"></i>
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </div>
          <div className="space-70"></div>
          <Row id="checkRadios">
            <Col className="mb-4" lg="3" sm="6">
              <p className="category">Checkboxes</p>
              <FormGroup check>
                <Label check>
                  <Input type="checkbox"></Input>
                  <span className="form-check-sign"></span>
                  Unchecked
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <Input defaultChecked type="checkbox"></Input>
                  <span className="form-check-sign"></span>
                  Checked
                </Label>
              </FormGroup>
              <FormGroup check disabled>
                <Label check>
                  <Input disabled type="checkbox"></Input>
                  <span className="form-check-sign"></span>
                  Disabled Unchecked
                </Label>
              </FormGroup>
              <FormGroup check disabled>
                <Label check>
                  <Input defaultChecked disabled type="checkbox"></Input>
                  <span className="form-check-sign"></span>
                  Disabled Checked
                </Label>
              </FormGroup>
            </Col>
            <Col className="mb-4" lg="3" sm="6">
              <p className="category">Radios</p>
              <FormGroup check className="form-check-radio">
                <Label check>
                  <Input
                    defaultValue="option1"
                    id="exampleRadios1"
                    name="exampleRadios"
                    type="radio"
                  ></Input>
                  <span className="form-check-sign"></span>
                  Radio is off
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio">
                <Label check>
                  <Input
                    defaultChecked
                    defaultValue="option2"
                    id="exampleRadios1"
                    name="exampleRadios"
                    type="radio"
                  ></Input>
                  <span className="form-check-sign"></span>
                  Radio is on
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" disabled>
                <Label check>
                  <Input
                    defaultValue="option3"
                    disabled
                    id="exampleRadios2"
                    name="exampleRadios1"
                    type="radio"
                  ></Input>
                  <span className="form-check-sign"></span>
                  Disabled radio is off
                </Label>
              </FormGroup>
              <FormGroup check className="form-check-radio" disabled>
                <Label check>
                  <Input
                    defaultChecked
                    defaultValue="option4"
                    disabled
                    id="exampleRadios2"
                    name="exampleRadios1"
                    type="radio"
                  ></Input>
                  <span className="form-check-sign"></span>
                  Disabled radio is on
                </Label>
              </FormGroup>
            </Col>
            <Col lg="3" sm="6">
              <p className="category">Toggle Buttons</p>
              <Switch offColor="" offText="" onColor="" onText=""></Switch>
              <br></br>
              <Switch defaultValue={false} offColor="" onColor=""></Switch>
            </Col>
            <Col lg="3" sm="6">
              <p className="category">Sliders</p>
              <div className="slider" id="sliderRegular"></div>
              <br></br>
              <div className="slider slider-primary" id="sliderDouble"></div>
            </Col>
          </Row> */}
        </Container>
      </div>
    </>
  );
}

export default BasicElements;
