import React from "react";
import "./style.css";
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@mui/material/TextField';
import { useParams, Redirect } from 'react-router-dom';
import Button from '@mui/material/Button';
import APIConfig from '../../confirmation/Config/ApiConfig';

// reactstrap components
import { Alert, Container } from "reactstrap";

// core components


function Notifications() {
  const { name } = useParams();
  const [Comments, SetComments] = React.useState([]);
  const [WrittenComment, SetWrittenComment] = React.useState('')
  const [WrittenName, SetWrittenName] = React.useState('')
  const [RefreshComment, SetRefreshComment] = React.useState(true)

  React.useEffect(async () => {
    // const DataComments =
    //   [
    //     {
    //       "nama": "Susanto",
    //       "comment": " Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    //       "timestamp": "Fri Oct 22 2021 14:18:37 GMT+0700"
    //     }, {
    //       "nama": "ivander",
    //       "comment": "Quisque arcu quam, malesuada vel mauris et, nec feugiat quam lectus vitae ex",
    //       "timestamp": "Fri Oct 22 2021 12:18:37 GMT+0700"
    //     }, {
    //       "nama": "Kevin",
    //       "comment": "  Pellentesque et neque risus. Aliquam vulputate, mauris vitaetincidunt interdum, mauris mi vehicula urna, nec feugiat quam lectus vitae ex",
    //       "timestamp": "Fri Oct 22 2021 11:18:37 GMT+0700"
    //     }
    //   ];

    // setTimeout(async () => {
    //   GetAllComment()
    // }, 5000)
    GetAllComment()
  }, [RefreshComment])

  const GetAllComment = async () => {
    const Response = await APIConfig.getComment()
    if (Response.status === '200') {
      SetComments(Response.Message)
      SetWrittenName(name)
    } else {
      alert('Ouch.. Telah terjadi kesalahan, mohon refresh browser kamu, dan isi ulang form nya, atau hubungi 0811668389 :) Terima kasih')
    }
  }

  const ListComment = Comments.map((comment) => {
    const CommentTimestamp = new Date(comment.timestamp);
    const createdDate = CommentTimestamp.toLocaleDateString('en-US');
    const createdTime = CommentTimestamp.toLocaleTimeString('en-US');
    return (
      <Grid item xs={12} sm={3} md={4}>
        <div style={{ padding: '10px' }}>
          <h3 
          className="titleTuliskanKenangan"
          >{comment.nama}</h3>
          <p style={{ textAlign: "left", fontSize: "12px", color: "gray", marginTop:-5 }}>
            {createdDate} -  {createdTime}
          </p> 
          <p style={{ textAlign: "left",marginTop:-15 }}>
            {comment.comment}
          </p> 
        </div>
      </Grid>
    )
  });

  const SendComment = async () => {
    if (WrittenComment !== "" && WrittenName !== "") {
      const DataComment = { "nama": WrittenName, "comment": WrittenComment }
      const Response = await APIConfig.sendComment(DataComment)
      console.log(Response)
      if (Response.status === '200') {
        SetWrittenComment("")
        SetWrittenName("")
        // SetComments([])
        { !RefreshComment ? SetRefreshComment(true) : SetRefreshComment(false) }
      } else {
        alert('Ouch.. Telah terjadi kesalahan, mohon refresh browser kamu, dan isi ulang form nya, atau hubungi 0811668389 :) Terima kasih')
      }

    } else {
      alert('Ada field yang kosong')
    }
  }

  return (
    <>
      <div style={{ marginTop: "40px" }} className="section section-notifications">
        <Container>
          <h1 className="titleTuliskanKenangan">
            Tuliskan ungkapan kamu untuk kami
          </h1>

          <div className="TextFieldComment" style={{ marginTop: '30px', display: 'flex', flexDirection: 'column' }}>
            <label>Nama Kamu</label>
            <TextField
              id="outlined-multiline-static"
              multiline
              placeholder="Nama"
              style={{ width: '40%' }}
              value={WrittenName}
              onChange={(e) => { SetWrittenName(e.target.value) }}
            />
          </div>

          <div style={{ marginTop: '15px', marginBottom: '15px', display: 'flex', flexDirection: 'column' }}>
            <label>Ungkapan Kamu</label>
            <TextField
              id="outlined-multiline-static"
              placeholder="Tuliskan Pesan"
              multiline
              rows={3}
              style={{ width: '80%' }}
              defaultValue="Default Value"
              value={WrittenComment}
              onChange={(e) => { SetWrittenComment(e.target.value) }}
            />
          </div>
          <Button style={{marginBottom:20}} onClick={() => { SendComment() }} variant="contained">Kirim</Button>
          <br/>
          <i style={{fontSize:13}}>Silahkan refresh halaman jika komentar Anda tidak muncul</i>
          <hr style={{color:'grey', marginBottom:20, marginTop:-2}}/>
         <div style={{}}>
         {Comments[0] === undefined ?
            <div>
              <p style={{ textAlign: "left", color: "gray" }}>
                No Comment
              </p>
            </div> :
            <Grid container
              direction="row"
              justify="flex-start"
              alignItems="flex-start"
              spacing={0} >
              {ListComment}
            </Grid>}
         </div>

        </Container>
      </div>
    </>
  );
}

export default Notifications;
